import React from "react"
import WhatLayout from "../../components/WhatLayout";
import bild01 from "../../images/what/yourface/blaetter-herbst.png";
import bild02 from "../../images/what/yourface/dexter.png";
import bild03 from "../../images/what/yourface/drachen.png";
import bild04 from "../../images/what/yourface/drachenauge.png";
import bild05 from "../../images/what/yourface/ink-schmetterlinge.png";
import bild06 from "../../images/what/yourface/kaffee-schmetterlinge.png";
import bild07 from "../../images/what/yourface/kolliies.png";
import bild08 from "../../images/what/yourface/moeven.png";
import bild09 from "../../images/what/yourface/raben.png";
import bild10 from "../../images/what/yourface/regenbogen-auge.png";

const Page = () => {

  return (
    <WhatLayout
      header={"Your Face"}
      images={[
        bild01,
        bild02,
        bild03,
        bild04,
        bild05,
        bild06,
        bild07,
        bild08,
        bild09,
        bild10
      ]}
    />
  );

};

export default Page;
